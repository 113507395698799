/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$theme-colors: (
	"danger": #EC040B,
	"warning": #FEBA35,
	"info": #005DAA,
	"success": #007738,
	"review": #FB8F25,
    "light": #17a2b8
);

@import "node_modules/bootstrap/scss/bootstrap"; 

body {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;	
}

h1 {
	font-size: 48px !important;
	line-height: 54px !important;
}

h2 {
	font-size: 24px !important;
}

h3 {
	font-size: 21px !important;
}

.mat-typography h5 {
	margin: 0px 0px 0px !important;
}

hr {
    background-color: #F1F1F1;
    height: 3px;
    margin: 3rem 0rem;
    border: none;
}

.screen-container {
	width: 726px;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;		
}

.logins {
	display: flex;
	flex-direction: column;
}

.logins-sub-header {
	display: flex;
	
	align-items: center;
	font-size: 22px;
	height: 237px;
	font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: #FFFFFF;
}

.card-body {
	padding: 0.75rem;
}

.invalid-feedback {
	font-size: 100%;
}

.countdown {
    margin-left: auto !important;
    align-items: center;   
    padding-top: 30px;
    font-size: 18px;
    color: #ff0000;
}

@media (min-width: 810px) {
	.card {
		width: 790px !important;
	}
}


@media (min-width: 576px) {
	h6 {				
		line-height: 28px !important;		
	}

	.card-body {
		padding: 1.25rem;
	}
}

.card-header {
	color: #333333 !important;
	font-size: 18px !important;
	font-weight: 700 !important;
	font-family: "AcuminPro-Bold", "Acumin Pro Bold", "Acumin Pro", sans-serif !important;
	height: 54px;
    display: flex block;
    align-items: center !important;
}

.form-check-label {    
	margin-top: 3px !important;
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #555555 !important;
}

.section-header {
	font-size: 18px;
	font-family: 'acumin-pro';
    font-weight: 400;
	font-style: normal;
	color: #333333;
	margin-right: 6px;
}

.section-label {
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #555555 !important;
	margin-bottom: 0.6rem !important; 
}

.section-hint {
	font-size: 13px; 
	color:#36424A;
}

.label-hint {
	font-size: 13px; 
	color:#36424A;
	font-weight: 400;
}

label {
	font-family: 'acumin-pro' !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: #555555 !important;
	margin-bottom: 0.6rem !important; 
}

input::placeholder {
	font-size: 14px !important;
	color: rgba(85, 85, 85, 0.7) !important;
}

input[type=text], input[type=date], input[type=time] {
	//border-color: rgba(102, 102, 102, 1) !important;
    border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
	font-style: normal !important;
	font-size: 13px !important;	
	padding: 3px 2px 3px 10px !important;
	height: 42px !important;
}

select {
	//border-color: rgba(102, 102, 102, 1) !important;
    border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
	font-style: normal !important;
	font-size: 13px !important;   
	color: #666666 !important;
	padding: 3px 2px 3px 10px !important;
	height: 42px !important;
}

.custom-control-label {
	outline: none;
}

.custom-control-label::before {    
    top: 0rem !important;    
}

.custom-control-label::after {    
    top: 0rem !important;    
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #0070C4 !important;
    background-color: #0070C4 !important;
}

.required:after {
    content:" (Required)";
	font-size: 13px; 
	font-weight: normal;
	color:#36424A;
  }
 
.error {
	color: red;
}

.form-control.is-invalid{
    background-image: none;
}

.icon-circle {
    background-color: #0070c4;
    background-position: center;
	background-repeat: no-repeat;
	background-size: 2.2em;
    min-width: 56px;
    min-height: 56px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 3rem;
}

.calendar {
    background-image: url("assets/images/calendar.svg");
}

.issue-date {
    background-image: url("assets/images/issue_date.svg");
}

.pay-penalty {
    background-image: url("assets/images/pay_penalty.svg");
}

.penalty-amount {
    background-image: url("assets/images/penalty_amount.svg");
}

.review-scales {
    background-image: url("assets/images/review_scales.svg");
}

.exclamation {
    background-image: url("assets/images/exclamation.svg");
}

.car-key {
    background-image: url("assets/images/car_key.svg");
}

.clock {
    background-image: url("assets/images/clock.svg");
}

div.goa-callout:before {
	top: 24px !important;
}

a.lnk-back {
	font-size: 16px;
}

a.lnk-back:before {
	margin-right: 8px;
	content: url("assets/images/left-arrow.svg");
}

#cardLogoDiv {
    float: left;
    display: inline-block;
    background-repeat: no-repeat;
    margin-top: 4px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.btn {
	color: #fff;
    text-decoration: none;
}

.btn:focus {
	box-shadow: none;
}

a {
	text-decoration: underline;
}

button:disabled, button:disabled:hover {
    border-color: rgba(195, 195, 195, 1);
    background-color: rgba(195, 195, 195, 1);
}

.uppercase
{
    text-transform: uppercase;
}

.close {
    font-size: 20px;
	color: #0070C4;
}

.close:hover {
    color: #0070C4;
    text-decoration: none;
}

.modal-title {
    font-weight: 700;
    font-style: normal;
    font-size: 24px !important;
    text-align: left;
}

.modal-body {
    font-size: 14px !important;
}

p {
	font-size: 16px !important;
}

p>i {
    font-style: italic !important;
}


a[target = '_blank'].extern:after {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin: 0px 0px -2px 5px;
	background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 22 20.51'%3E%3Cpath fill='%23333' d='M21,19.69a.58.58,0,0,1-.57.57H3.57A.58.58,0,0,1,3,19.69V4.31a.58.58,0,0,1,.57-.57H6.82v-2H3.57A2.58,2.58,0,0,0,1,4.31V19.69a2.58,2.58,0,0,0,2.57,2.57H20.43A2.58,2.58,0,0,0,23,19.69V16.44H21Z' transform='translate(-1 -1.74)'/%3E%3Cpath fill='%23333' d='M22,1.75H14.15a1,1,0,1,0,0,2h5.44l-7.41,7.41a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3L21,5.17V10.6a1,1,0,1,0,2,0V2.75A1,1,0,0,0,22,1.75Z' transform='translate(-1 -1.74)'/%3E%3C/svg%3E") no-repeat center center;
	content: '';
  }

.vfs-tip {
    display: inline;
    color: #0070C4;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
}

.vfs-tip:hover::before {
    position: absolute;
    content: "A victim surcharge is a fine imposed on a person convicted of an offence or who commits a contravention under provincial laws. These funds are used to provide programs, services and assistance to victims of crime and to fund public safety initiatives to deter crime and reduce victimization.";
    top: -12rem;
    left: -5rem;
    height: 180px;
    width: 320px;
    border-style: solid;
    border-width: thin;
    border-radius: .3rem;
    border-color:rgba(170, 170, 170, 0.7);
    background:#FFFFFF;
    transform: translateX(-50%);
    color: #333333;
    padding: 0.5rem;
}  

.checkbox {
    position: relative;
    display: block;
}

.checkbox label {
	font-weight: normal !important;
	margin-left: 20px;
}
	
.checkbox input[type=checkbox] {
	position: absolute;
	margin-top: 4px;
}

.checkbox-lg {
    display:flex;
    align-items:center;
}

.checkbox-lg [type='checkbox'] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.checkbox-lg label {
    margin-bottom: 0px !important;
}

.heading-alert {
    max-width: 1080px !important;
}

.print-icon {
    cursor: pointer;
    background-image: url("assets/images/print-icon.svg");
    background-position: center;
	background-repeat: no-repeat;
	background-size: 3em;
    min-width: 56px;
    min-height: 56px;
}

.question-icon {
    display: inline;
    font-size: 12px;
    color: #0070C4;
    cursor: pointer;
}

@media print {
    .not-for-print {
        display: none;
    }
    .collapse {
        display: block !important;
        height: auto !important;
    }
  }

.btn-block {
    width: 100% !important;
}

.btn-close {
    --bs-btn-close-bg: none !important;
}