
/*

Government of Alberta Design System

Version: V 1.0.1

Full system available at https://www.alberta.ca/goa/design-system/

*/


/* typekit ***/

@import url("https://p.typekit.net/p.css?s=1&k=nur5yta&ht=tk&f=26036.26037.26044.26046.26047&a=14638192&app=typekit&e=css");
@import url("https://p.typekit.net/p.css?s=1&k=vtw4mkq&ht=tk&f=26053.26054.26056.26062.25998.25999.26000.26001.26016.26017.26018.26019.26036.26037.26046.26047.26072.26073.26086.26087&a=897862&app=typekit&e=css");

@font-face {
  font-family: "acumin-pro-semi-condensed";
  src: url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/l?subset_id=1&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/d?subset_id=1&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/a?subset_id=1&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "acumin-pro-semi-condensed";
  src: url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/l?subset_id=1&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/d?subset_id=1&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/a?subset_id=1&fvd=i4&v=3") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "acumin-pro-semi-condensed";
  src: url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/l?subset_id=1&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/d?subset_id=1&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/a?subset_id=1&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "acumin-pro-semi-condensed";
  src: url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/l?subset_id=1&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/d?subset_id=1&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/a?subset_id=1&fvd=i7&v=3") format("opentype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "acumin-pro-semi-condensed";
  src: url("https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/l?subset_id=1&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/d?subset_id=1&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/e60e87/00000000000000003b9acb31/27/a?subset_id=1&fvd=n6&v=3") format("opentype");
  font-style: normal;
  font-weight: 600;
}

.tk-acumin-pro-semi-condensed {
  font-family: "acumin-pro-semi-condensed", sans-serif;
}

@font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;
  }
  
  @font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;
  }
  
  @font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;
  }
  
  @font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;
  }





/******************************************************************************
* elements
* 
***/

body, input, textarea {
  background: #fff;
  font-family: "acumin-pro", sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #333333 !important;
}

h1 {
  margin: 0 !important;
  padding: 0 !important;
  /*
  line-height: 56px !important;
  font-size: 48px !important;
  */

  font-weight: 400 !important;
  font-style: normal  !important;
  font-size: 36px  !important;  
  line-height: 44px  !important;
  font-family: 'acumin-pro', sans-serif !important;
  color: #333333 !important;
}

h2 {
  margin: 24px 0 !important;
  padding: 0 !important;
  line-height: 44px !important;
  color: #333 !important;
  font-size: 36px !important;
  font-weight: 400 !important;
  font-family: 'acumin-pro-semi-condensed', sans-serif !important;
}

h3 {
  margin: 24px 0 !important;
  padding: 0 !important;
  line-height: 32px !important;
  color: #333 !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  font-family: "acumin-pro-semi-condensed", sans-serif !important;
}

h4 {  
  padding: 0;
  line-height: 28px;
  color: #333;
  font-size: 17px !important;
  font-weight: 700 !important;
  font-family: "acumin-pro", sans-serif !important;
}

h5 {  
  padding: 0;
  line-height: 28px;
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: 'acumin-pro', sans-serif !important;
}

h6 {
  margin: 23px 0 10px !important;
  padding: 0;
  line-height: 28px !important;
  color: #333333 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: 'acumin-pro', sans-serif !important;
}

p {  
  margin-top: 16px !important;
  font-size: 14px !important;
  line-height: 1.3 !important;
  font-family: 'acumin-pro', sans-serif !important;
}


dt {
  font-weight: 700;
}

li {
  margin: 13px 0 0 28px;
  font-size: 18px;
  line-height: 28px;
}

ul {
  list-style-type: disc;
  margin-top: 28px;
}

ul ul {
  list-style-type: disc;
}

a:link {
  color: #0070c4;
}

a:visited {
  color: #756693;
}

a:hover {
  color: #004f84;
}

a:focus {
  outline-width: thin;
  outline-style: solid;
  outline-color: #004f84;
  outline-offset: 0px;
}


/* anchors *******************************************************************/

html:not([dir = 'rtl']) main a[target = '_blank']:after {
  display: inline-block;
  width: 17px;
  height: 16px;
  margin: 0px 0px -2px 5px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 22 20.51'%3E%3Cpath fill='%23333' d='M21,19.69a.58.58,0,0,1-.57.57H3.57A.58.58,0,0,1,3,19.69V4.31a.58.58,0,0,1,.57-.57H6.82v-2H3.57A2.58,2.58,0,0,0,1,4.31V19.69a2.58,2.58,0,0,0,2.57,2.57H20.43A2.58,2.58,0,0,0,23,19.69V16.44H21Z' transform='translate(-1 -1.74)'/%3E%3Cpath fill='%23333' d='M22,1.75H14.15a1,1,0,1,0,0,2h5.44l-7.41,7.41a1,1,0,0,0,.71,1.71,1,1,0,0,0,.7-.3L21,5.17V10.6a1,1,0,1,0,2,0V2.75A1,1,0,0,0,22,1.75Z' transform='translate(-1 -1.74)'/%3E%3C/svg%3E") no-repeat center center;
  content: '';
}

a[href $= '.pdf']:after,
a[href $= '.doc']:after,
a[href $= '.docx']:after {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0px 0px -3px 5px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' shape-rendering='geometricPrecision'%3E%3Cpath d='M0 16L16 16L16 14L0 14Z  M8 14L0 6L4 6L4 0L12 0L12 6L16 6Z' fill='%2336424a'/%3E%3C/svg%3E") no-repeat center center;
  content: '';
}



/******************************************************************************
* classes
* 
***/


/* goa-button ****************************************************************/

button.goa-button,
input[type="button"].goa-button,
input[type="submit"].goa-button {
    box-sizing: border-box;
    min-width: 70px;
    margin: 28px 12px 0 0;
    padding: 12px;
    border: 2px solid #0070c4;
    border-radius: 4px;
    background: #0070c4;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
}

button.goa-button:hover,
input[type="button"].goa-button:hover,
input[type="submit"].goa-button:hover {
    border-color: #004f84;
    background: #004f84;
}

button.goa-button:focus,
input[type="button"].goa-button:focus,
input[type="submit"].goa-button:focus {
    border-color: #004f84;
    outline: 3px solid #feba35;
    outline-offset: 0;
    background: #004f84;
}

button.goa-button:active,
input[type="button"].goa-button:active,
input[type="submit"].goa-button:active {
    background: #666;
    color: #fff;
    border: 2px solid #666;
    outline: none;
}

/* variants ***/
button.goa-button.goa--secondary,
input[type="button"].goa-button.goa--secondary {
    border: 2px solid #0070c4;
    background: #fff;
    color: #0070c4;
}

button.goa-button.goa--secondary:hover,
input[type="button"].goa-button.goa--secondary:hover {
    border-color: #004f84;
    background: #f1f1f1;
    color: #004f84;
}

button.goa-button.goa--secondary:focus,
input[type="button"].goa-button.goa--secondary:focus {
    border-color: #004f84;
    background: #f1f1f1;
    color: #004f84;
}

button.goa-button.goa--secondary:active,
input[type="button"].goa-button.goa--secondary:active,
input[type="submit"].goa-button.goa--secondary:active {
    background: #DCDCDC;
    color: #333;
    border: 2px solid #333;
    outline: none;
}

button.goa-button.goa--tertiary,
input[type="button"].goa-button.goa--tertiary {
    border-color: #f1f1f1;
    background: #fff;
    color: #0070c4;
}

button.goa-button.goa--tertiary:hover,
input[type="button"].goa-button.goa--tertiary:hover {
    background: #f1f1f1;
    color: #004f84;
}

button.goa-button.goa--tertiary:focus,
input[type="button"].goa-button.goa--tertiary:focus {
    border-color: #004f84;
    background: #f1f1f1;
    color: #004f84;
}


/* goa-link-button ***********************************************************/

a.goa-link-button {
    display: inline-block;
    box-sizing: border-box;
    min-width: 70px;
    margin: 28px 12px 0 0;
    padding: 9px 12px;
    border: 2px solid #0070c4;
    border-radius: 4px;
    background: #0070c4;
    cursor: pointer;
    color: #fff !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
}

a.goa-link-button::after {
    margin-left: 8px;
    content: '\2192';
    /*content: '\2191';*/ /* up arrow*/
}

a.goa-link-button:hover {
    border-color: #004f84;
    background: #004f84;
}

a.goa-link-button:focus, a.goa-link-button:active {
    border-color: #004f84;
    outline: 3px solid #feba35;
    outline-offset: 0;
    background: #004f84;
}


/* goa-form ******************************************************************/

/* label ***/

div.goa-form {
  padding: 28px 36px;
  background-color: #FFFFFF;
  font-family: 'acumin-pro-semi-condensed', sans-serif !important;
}

div.goa-form label {
  display: block;
  margin: 14px 0 0;
  font-size: 14px;
  
}

  div.goa-form label kbd {
    color: #666;
    font-size: 14px;
    font-weight: 400;
  }

  div.goa-form label span.optional {
    color: #666;
    font-size: 14px;
    font-weight: 400;
  }


/* is the input moving on hover/focus */

/* input ***/

div.goa-form input:not([type='checkbox']):not([type='radio']) {
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0 0;
  padding: 12px;
  line-height: 1em;
  border-radius: 4px;
  border: 1px solid #666;
  font-size: 18px;
  font-weight: 400;
}

  div.goa-form input:not([type='checkbox']):not([type='radio']):hover {
    border: 1px solid #004f84;
  }

  div.goa-form input:not([type='checkbox']):not([type='radio']):focus {
    outline: 3px solid #feba35;
    outline-offset: 0;
  }

/* textarea ***/

div.goa-form textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0 0;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #666;
  font-size: 18px;
  font-weight: 400;
}

  div.goa-form textarea:hover {
    border: 1px solid #004f84;
  }

  div.goa-form textarea:focus {
    outline: 3px solid #feba35;
    outline-offset: 0;
  }

/* select ***/

div.goa-form select {
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0 0;
  padding: 12px;
  line-height: 1em;
  border-radius: 4px;
  border: 1px solid #666;
  font-size: 18px;
  font-weight: 400;
  /* removes default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* remove default arrow IE */
div.goa-form select::-ms-expand {
  display: none;
}

  div.goa-form select:hover {
    border: 1px solid #004f84;
  }

  div.goa-form select:focus {
    outline: 3px solid #feba35;
    outline-offset: 0;
  }

/* add chevron */
i.goa-select-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='cls-1' d='M11.29,15.7a1,1,0,0,0,1.41,0l6-6A1,1,0,1,0,17.29,8.3L12,13.58,6.71,8.3a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41Z'/%3E%3C/svg%3E%0A"); left: auto;
  right: 8px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  position: absolute;
  margin-top: 20px;
}

div.goa-form div.goa-option {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 28px;
  margin: 24px 0 0;
  vertical-align: middle;
}

div.goa-form .goa-field-select,
div.goa-form .goa--required {
  position: relative;
}

div.goa-form [type='checkbox']:not(:checked),
div.goa-form [type='radio']:not(:checked),
div.goa-form [type='checkbox']:checked,
div.goa-form [type='radio']:checked {
  z-index: 1;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  left: 2px;
  margin: 0;
}

  div.goa-form [type='checkbox']:not(:checked) + label,
  div.goa-form [type='radio']:not(:checked) + label,
  div.goa-form [type='checkbox']:checked + label,
  div.goa-form [type='radio']:checked + label {
    z-index: 2;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0 0 0 51px;
    cursor: pointer;
  }

    /* checkbox ***/

    div.goa-form [type='checkbox']:not(:checked) + label:before,
    div.goa-form [type='checkbox']:checked + label:before {
      z-index: 10000;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid #666;
      border-radius: 2px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
    }

html div.goa-form [type='checkbox']:checked + label:before {
  background-color: #004f84;
}

div.goa-form [type='checkbox']:not(:checked):hover + label:before,
div.goa-form [type='checkbox']:checked:hover + label:before {
  border-color: #004f84;
}

div.goa-form [type='checkbox']:not(:checked):focus + label:before,
div.goa-form [type='checkbox']:checked:focus + label:before {
  outline: 3px solid #feba35;
  outline-offset: 0;
}

/* radio ***/

div.goa-form [type='radio']:not(:checked) + label:before,
div.goa-form [type='radio']:checked + label:before {
  z-index: 10000;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #666;
  border-radius: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
}

div.goa-form [type='checkbox']:checked + label:after {
  z-index: 10001;
  box-sizing: border-box;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #fff;
  content: '';
}

div.goa-form [type='radio']:checked + label:after {
  z-index: 10001;
  box-sizing: border-box;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 100%;
  content: '';
}

html div.goa-form [type='radio']:checked + label:before {
  background-color: #004f84;
}

div.goa-form [type='radio']:not(:checked):hover + label:before,
div.goa-form [type='radio']:checked:hover + label:before {
  border-color: #004f84;
}

div.goa-form [type='radio']:not(:checked):focus + label:before,
div.goa-form [type='radio']:checked:focus + label:before {
  outline: 3px solid #feba35;
  outline-offset: 0;
}

/* fieldset ***/

div.goa-form fieldset {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 24px 0 0;
  padding: 0 20px 20px;
  border: 1px solid #dcdcdc;
}

div.goa-form legend {
  box-sizing: border-box;
  width: calc(100% + 42px);
  margin-left: -21px;
  padding: 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #666;
  color: #fff;
  font-weight: 700;
}

/* recaptcha ***/

div.goa-form div.recaptcha {
  margin: 28px 0 0;
}

div.goa-form iframe.recaptcha-frame {
  width: 302px;
  height: 423px;
  border: none;
}

/* goa-form SVGs */

div.goa-form [type='checkbox']:checked + label:before {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' shape-rendering='geometricPrecision'%3E%3Cpath d='M1 10L6 14L15 1' fill='transparent' stroke='%23fff' stroke-width='3' stroke-endcap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}


div.goa-form div.goa-field.goa-error label:before {
	background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 28 28' shape-rendering='geometricPrecision'%3E%3Cpath fill='%23c00' fill-rule='evenodd' d='M0 14A14 14 0 0 1 28 14A14 14 0 0 1 0 14Z M11 6A3 3 0 0 1 17 6L17 14A3 3 0 0 1 11 14Z M11 22A3 3 0 0 1 17 22A3 3 0 0 1 11 22Z'/%3E%3C/svg%3E") no-repeat center center;
}


/* goa-image *****************************************************************/

div.goa-image {
  width: 100%;
}

div.goa-image img {
  width: 100%;
  vertical-align: top;
}

div.goa-image.goa--left {
  float: left;
  width: 40%;
  margin: 24px 24px 24px 0;
}

div.goa-image.goa--right {
  float: right;
  width: 40%;
  margin: 24px 0 24px 24px;
}

p.goa-text--wrap {
  margin-top: 0;
}

div.goa-image.goa--full {
  width: 100%;
  margin: 24px 0 0 0;
}

/* goa-caption */

div.goa-caption {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 12px;
  background: #f1f1f1;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}


/* list **********************************************************************/

/* variants ***/

ol.goa-list--alpha {
	list-style-type: upper-alpha;
}
ol.goa-list--alpha ol {
	list-style-type: lower-alpha;
}
ol.goa-list--alpha ol ol {
	list-style-type: lower-roman;
}
ol.goa-list--alpha ol ol ol {
	list-style-type: decimal;
}
ol.goa-list--alpha ol ol ol ol {
	list-style-type: lower-alpha;
}
ol.goa-list--alpha ol ol ol ol ol {
	list-style-type: lower-roman;
}
ol.goa-list--alpha ol ol ol ol ol ol {
	list-style-type: decimal;
}


/* goa-progress **************************************************************/

div.goa-progress {
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	margin: 8px 0 0;
	padding: 0;
}
div.goa-progress div.progress-bar {
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	width: 100%;
	height: 24px;
	border-bottom: 1px solid #efefef;
	border-radius: 5px;
	background: #f1f1f1 linear-gradient(#dcdcdc 0%, #f1f1f1 100%) repeat-x top left;
}
div.goa-progress div.progress-fill {
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	width: 1%;
	height: 100%;
	border-radius: 5px;
	background: #0081ab;
}
div.goa-progress div.progress-label {
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	margin: 8px 0 0;
	padding: 0;
	color: #0081ab;
	font-size: 18px;
	font-weight: 700;
	line-height: 1em;
	text-align: center;
	vertical-align: top;
}
div.step-current {
	margin: 28px 0 0;
	font-size: 18px;
	font-weight: 700;
}


/* goa-table *****************************************************************/

div.goa-table {
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	margin: 28px 0 0;
	padding: 0;
	overflow-y: hidden;
	overflow-x: auto;
	vertical-align: top;
	font-size: 18px;
	line-height: 24px;
}
div.goa-table table {
	width: 100%;
	border-collapse: collapse;
}
div.goa-table th {
	padding: 12px;
	text-align: left;
}
div.goa-table td {
	padding: 12px;
}
div.goa-table thead {
	border-bottom: 1px solid #666;
}
div.goa-table thead th {
	vertical-align: bottom;
	background: #f1f1f1;
	color: #333;
}
div.goa-table thead tr + tr {
	border-top: 1px solid #fff;
}
div.goa-table thead tr th + th {
	border-left: 1px solid #fff;
}
div.goa-table tbody tr {
	background: #fff;
}
div.goa-table tbody tr:nth-of-type(odd) {
	background: #efefef;
}
div.goa-table tbody th {
	border-right: 1px solid #666;
}
div.goa-table tfoot {
	border-top: 1px solid #666;
}

/* goa-tableouter ***/

div.goa-tableouter {
	z-index: 500;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	overflow: hidden;
	width: 100%;		
	margin: 28px 0 0;
	vertical-align: top;
}
div.goa-tableouter div.table {
	margin: 0px;
}
div.goa-tableouter.goa--end:before,
div.goa-tableouter.goa--middle:before {
	z-index: 1000;
	position: absolute;
	top: 0;
	right: 100%;
	width: 56px;
	height: 100%;
	box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.35);
	content: '';
}
div.goa-tableouter.goa--start:after,
div.goa-tableouter.goa--middle:after {
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 100%;
	width: 56px;
	height: 100%;
	box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.35);
	content: '';
}
div.goa-tableouter > div.goa-table {
	margin: 0;
}

/* icons *********************************************************************/

.goa-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
}


/******************************************************************************
* breakpoint: mobile
* 
***/

@media screen and (max-width: 500px) {

  /* elements ****************************************************************/

  h1 {
    margin: 0;
    padding: 0;
    line-height: 44px;
    font-size: 36px;
  }

  h2 {
    margin: 32px 0 0;
    padding: 0;
    line-height: 40px;
    color: #333;
    font-size: 32px;
    font-weight: 400;
  }

  /* goa-form **************************************************************/

  div.goa-form button {
    width: 100%;
    margin-right: 0;
  }


  /* goa-table *************************************************************/

  div.goa-table table th,
  div.goa-table table td {
    white-space: nowrap;
  }

}



/******************************************************************************
* breakpoint: tablet
* 
***/

@media screen and (min-width: 500px) and (max-width: 768px) {


  /* goa-table *************************************************************/

  div.goa-table table th,
  div.goa-table table td {
    white-space: nowrap;
  }

}



/******************************************************************************
* breakpoint: desktop
* 
***/

@media screen and (min-width: 1200px) {

}